import React, { useEffect, useRef, useState } from 'react'
import { twcx } from 'helpers'
import styles from './InfiniteScroller.module.css'


type Direction = 'left' | 'right' | 'up' | 'down'

type InfiniteScrollerProps = {
  children: React.ReactNode
  speed?: number // seconds for one complete loop
  className?: string
  direction?: Direction
  pauseOnHover?: boolean
  pauseOnClick?: boolean
  gradientColor?: string
  onComplete?: () => void
}

const InfiniteScroller: React.FC<InfiniteScrollerProps> = (props) => {
  const {
    children,
    speed = 30,
    className = '',
    direction = 'left',
    pauseOnHover = false,
    pauseOnClick = false,
    gradientColor = '',
    onComplete,
  } = props

  const scrollerRef = useRef<HTMLDivElement>(null)
  const [ dimensions, setDimensions ] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const updateContentSize = () => {
      if (scrollerRef.current?.firstElementChild) {
        const element = scrollerRef.current.firstElementChild

        if (element) {
          setDimensions({
            width: element.clientWidth,
            height: element.clientHeight,
          })
        }
      }
    }

    updateContentSize()

    window.addEventListener('resize', updateContentSize)
    return () => window.removeEventListener('resize', updateContentSize)
  }, [])

  const isVertical = direction === 'up' || direction === 'down'

  const scrollerStyle = {
    '--scroller-speed': `${speed}s`,
    '--content-width': `${dimensions.width}px`,
    '--content-height': `${dimensions.height}px`,
    transform: isVertical && 'rotate(-90deg)',
  } as React.CSSProperties

  const scrollerClassName = twcx(
    styles.scroller,
    {
      [styles.reverse]: direction === 'right' || direction === 'down',
      [styles.vertical]: isVertical,
      [styles.pauseOnHover]: pauseOnHover,
      [styles.pauseOnClick]: pauseOnClick,
    },
    'flex'
  )

  return (
    <div className={twcx('relative w-full overflow-hidden', className)}>
      {Boolean(gradientColor) && (
        <div className="pointer-events-none absolute inset-0 z-10">
          {[ 'right', 'left' ].map((direction) => (
            <div
              key={direction}
              className={`absolute ${direction}-0 top-0 h-full w-[100px]`}
              style={{
                background: `linear-gradient(to ${direction === 'left' ? 'right' : 'left'}, ${gradientColor}, transparent)`,
              }}
            />
          ))}
        </div>
      )}

      <div
        ref={scrollerRef}
        className={scrollerClassName}
        style={scrollerStyle}
        onAnimationEnd={onComplete}
      >
        {[ 0, 1 ].map((index) => (
          <div key={index} className={twcx('flex shrink-0', isVertical && 'rotate-90')}>
            {children}
          </div>
        ))}
      </div>
    </div>
  )
}

export default InfiniteScroller
